<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="620px"
  >
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-icon
              size="20"
              @click="dialog = false"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>

          <v-col align="center">
            <h5>Import Employee from Excel</h5>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>
      <v-form @submit.prevent="addEmployeeList()">
        <v-card-text class="py-8 px-8">
          <v-row
            align="center"
            justify="space-between"
            class="mb-2"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <span style="font-size: 0.9rem">Please upload file according to template</span>
            </v-col>

            <v-col
              cols="12"
              sm="auto"
              align="end"
            >
              <v-btn
                text
                class="font-weight-bold pa-0"
                :block="$vuetify.breakpoint.xs"
                :outlined="$vuetify.breakpoint.xs"
                href="/files/Template_Employee_List.xlsx"
                download
              >
                <v-icon
                  left
                  small
                >
                  {{ icons.mdiDownload }}
                </v-icon>
                Download template
              </v-btn>
            </v-col>
          </v-row>

          <v-file-input
            v-model="excelFile"
            outlined
            type="file"
            label="Choose excel file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="getExcel()"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            type="submit"
            :disabled="employeeListData.length === 0 || !excelFile"
            :loading="loading"
            @click.prevent="addEmployeeList()"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiDownload } from '@mdi/js'
import XLSX from 'xlsx'
import bankList from './bank-channels.json'

export default {
  name: 'ImportEmployee',
  props: {
    value: { type: Boolean, required: true },
  },
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      icons: { mdiClose, mdiDownload },
    }
  },
  data() {
    return {
      excelFile: null,
      employeeListData: [],
      loading: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  methods: {
    getExcel() {
      //console.log('masukkkk')
      if (this.excelFile === null) return

      const reader = new FileReader()
      reader.readAsBinaryString(this.excelFile)

      reader.onload = async e => {
        const binaryData = e.target.result
        const workbook = XLSX.read(binaryData, { type: 'binary' })

        let data = []
        workbook.SheetNames.forEach(el => {
          data = XLSX.utils.sheet_to_json(workbook.Sheets[el])
        })

        const employeeListData = data.map(el => ({
          name: el.Name,
          phoneNumber: `0${el['Phone Number'].toString().substring(2)}`,
          email: el.Email,
          bankAccount: el['Bank Account (optional)'],
          bankCode: bankList.find(bank => bank.name === el['Bank Account (optional)'])?.code,
          accountNumber: el['Account Number (optional)'],
          ID_employeeGroup: this.$route.params.id,
        }))

        this.employeeListData = employeeListData
      }
    },
    async addEmployeeList() {
      this.loading = true
      await this.$store.dispatch('manageSalary/bulkCreateEmployee', {
        sub_id: this.user.sub_id,
        employeeGroupId: this.$route.params.id,
        employeeListData: this.employeeListData,
      })

      await this.$store.dispatch('manageSalary/getEmployeesByEmployeeGroupID', {
        sub_id: this.user.sub_id,
        ID_employeeGroup: this.$route.params.id,
      })

      this.loading = false
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
