<template>
  <v-container>
    <!-- Header -->
    <v-col
      align="start"
      justify="center"
      class="pa-0 mb-5"
    >
      <v-icon
        color="primary"
        style="cursor: pointer;"
        @click="$router.go(-1)"
      >
        {{ icons.mdiChevronLeft }}
      </v-icon>
      <h3
        style="display: inline-block"
        class="ms-4"
      >
        {{ employeeGroupName }}
      </h3>
    </v-col>

    <!-- Tab -->
    <v-tabs
      v-model="tabs"
      class="mb-5"
    >
      <v-tab class="text-capitalize">
        List Employee
      </v-tab>
      <v-tab class="text-capitalize">
        List Disbursement
      </v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <list-employee />
      </v-tab-item>

      <v-tab-item>
        <list-disbursement />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiChevronLeft } from '@mdi/js'

import ListEmployee from './ListEmployee.vue'
import ListDisbursement from './ListDisbursement.vue'

export default {
  name: 'EmployeeGroup',
  components: { ListEmployee, ListDisbursement },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: { mdiChevronLeft },
    }
  },
  data() {
    return {
      tabs: null,
      showCreateEmployeeGroupModal: false,
    }
  },
  computed: {
    employeeGroupName() {
      return this.$store.getters['manageSalary/getCurrentEmployeeGroup'].name
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>