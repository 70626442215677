<template>
  <div>
    <!-- Toolbar -->
    <v-row class="mb-6">
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click.stop="$router.push({ name: 'create-disbursement', params: { employeeGroupId: $route.params.id } })"
        >
          Create Disbursement
        </v-btn>
      </v-col>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search disbursement"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>


    <v-data-table
      :headers="headers"
      :items="disbursementList"
      :search="search"
      item-key="name"
      class="elevation-1"
    >
      <template v-slot:item.no="{ item }">
        {{ disbursementList.findIndex((disbursement) => disbursement.id === item.id) + 1 }}.
      </template>

      <template v-slot:item.disbursementDate="{ item }">
        {{ $moment(new Date(item.disbursementDate - new Date().getTimezoneOffset())).format('DD MMM YYYY HH:mm') }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ $moment(new Date(item.createdAt)).format('DD MMM YYYY HH:mm') }}
      </template>

      <template v-slot:item.employeeCount="{ item }">
        {{ item.employees ? item.employees.length : 0 }}
      </template>

      <template v-slot:item.totalAmount="{ item }">
        {{ formattedCurrency(item.disbursementTotalAmount) }}
      </template>

      <template v-slot:item.status="{ item }">
        <div :style="`color: ${statusColor[item.status]}`">
          {{ statusText[item.status] }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          class="text-capitalize"
          small
          outlined
          :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
          color="primary"
          @click="toDisbursementDetail(item.id)"
        >
          View Detail
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
  mdiDatabaseImportOutline,
} from '@mdi/js'

export default {
  name: 'ListDisbursement',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')
    const statusText = {
      done: 'Done',
      inProgress: 'In progress',
      approved: 'Approved',
      rejected: 'Rejected',
      waitingApproval: 'Waiting Approval',
    }
    const statusColor = {
      done: '#44A780',
      inProgress: '#FCBE2D',
      approved: '#9E9E9E',
      rejected: '#FF6666',
      waitingApproval: '#999999',
    }

    return {
      isDark,
      search,
      statusText,
      statusColor,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
        mdiDatabaseImportOutline,
      },
    }
  },
  data() {
    return {
      showAddEmployeeModal: false,
      headers: [
        {
          text: 'No.',
          align: 'start',
          value: 'no',
        },
        {
          text: 'Disbursement date',
          align: 'start',
          value: 'disbursementDate',
        },
        {
          text: 'Disbursement created',
          align: 'start',
          value: 'createdAt',
        },
        { text: 'Employee', value: 'employeeCount' },
        { text: 'Employee paid', value: 'employeesPaid' },
        { text: 'Amount', value: 'totalAmount' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    disbursementList() {
      return this.$store.getters['manageSalary/getDisbursementList']
    },
  },
  created() {
    this.$store.dispatch('manageSalary/getDisbursementList', {
      sub_id: this.user.sub_id,
      employeeGroupId: this.$route.params.id,
    })
  },
  methods: {
    formattedCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    async toDisbursementDetail(disbursementId) {
      await this.$store.dispatch('manageSalary/getDisbursementById', { sub_id: this.user.sub_id, id: disbursementId })
      this.$router.push(`/manage-salary/employee-group/${this.$route.params.id}/disbursement/${disbursementId}`)
    },
  },
}
</script>
