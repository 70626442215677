var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.showAddEmployeeModal = true; _vm.formType = 'create'}}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Add Employee ")],1),_c('add-employee-modal',{attrs:{"form-type":_vm.formType,"employee-group-i-d":_vm.employeeGroupID},model:{value:(_vm.showAddEmployeeModal),callback:function ($$v) {_vm.showAddEmployeeModal=$$v},expression:"showAddEmployeeModal"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","outlined":"","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.showImportEmployeeModal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDatabaseImportOutline)+" ")]),_vm._v(" Import ")],1),_c('import-employee-modal',{model:{value:(_vm.showImportEmployeeModal),callback:function ($$v) {_vm.showImportEmployeeModal=$$v},expression:"showImportEmployeeModal"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search employee","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.employeeList,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.employeeList.findIndex(function (employee) { return employee.id === item.id; }) + 1)+". ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',[_vm._v(_vm._s(item.phoneNumber ? item.phoneNumber : "--"))])])]}},{key:"item.bankInfo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.bankAccount ? item.bankAccount : '--')+" ")]),_c('div',[_vm._v(_vm._s(item.accountNumber ? item.accountNumber : '--'))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email ? item.email : "--")+". ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"text-capitalize me-4",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toEmployeeDetails(item.id)}}},[_vm._v(" View Detail ")]),_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editEmployee(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.toEmployeeDetails(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountGroup))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editEmployee(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)]}}])}),_c('confirm-delete-modal',{attrs:{"item-id":_vm.itemId,"loading":_vm.deleteLoading},on:{"handle-delete":_vm.deleteEmployee},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}},[_vm._v(" Are you sure you want to delete this employee? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }