<template>
  <div>
    <!-- Toolbar -->
    <v-row class="mb-6">
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click="showAddEmployeeModal = true; formType = 'create'"
        >
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Employee
        </v-btn>

        <add-employee-modal
          v-model="showAddEmployeeModal"
          :form-type="formType"
          :employee-group-i-d="employeeGroupID"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          class="text-capitalize"
          color="primary"
          outlined
          :block="$vuetify.breakpoint.smAndDown"
          @click="showImportEmployeeModal = true"
        >
          <v-icon left>
            {{ icons.mdiDatabaseImportOutline }}
          </v-icon>
          Import
        </v-btn>

        <import-employee-modal v-model="showImportEmployeeModal" />
      </v-col>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search employee"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>


    <v-data-table
      :headers="headers"
      :items="employeeList"
      :search="search"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.no="{ item }">
        {{ employeeList.findIndex((employee) => employee.id === item.id) + 1 }}.
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex flex-column">
          <div class="font-weight-bold">
            {{ item.name }}
          </div>
          <div>{{ item.phoneNumber ? item.phoneNumber : "--" }}</div>
        </div>
      </template>

      <template v-slot:item.bankInfo="{ item }">
        <div class="d-flex flex-column">
          <div class="font-weight-bold">
            {{ item.bankAccount ? item.bankAccount : '--' }}
          </div>
          <div>{{ item.accountNumber ? item.accountNumber : '--' }}</div>
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.email ? item.email : "--" }}.
      </template>

      <template v-slot:item.action="{ item }">
        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="text-capitalize me-4"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toEmployeeDetails(item.id)"
            >
              View Detail
            </v-btn>
            <v-btn
              class="action-btn me-4"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="editEmployee(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon
              color="primary"
              @click="toEmployeeDetails(item.id)"
            >
              <v-icon>{{ icons.mdiAccountGroup }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="editEmployee(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <confirm-delete-modal
      v-model="showDeleteConfirmationModal"
      :item-id="itemId"
      :loading="deleteLoading"
      @handle-delete="deleteEmployee"
    >
      Are you sure you want to delete this employee?
    </confirm-delete-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
  mdiDatabaseImportOutline,
} from '@mdi/js'

import AddEmployeeModal from './AddEmployee.vue'
import ImportEmployeeModal from './ImportEmployee.vue'
import ConfirmDeleteModal from '../shared/ConfirmDelete.vue'

export default {
  name: 'ListEmployee',
  components: { AddEmployeeModal, ImportEmployeeModal, ConfirmDeleteModal },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
        mdiDatabaseImportOutline,
      },
    }
  },
  data() {
    return {
      showAddEmployeeModal: false,
      showImportEmployeeModal: false,
      headers: [
        {
          text: 'No.',
          align: 'start',
          value: 'no',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Bank Info', value: 'bankInfo' },
        { text: 'Email', value: 'email' },
        { text: 'Action', align: 'end', value: 'action' },
      ],
      formType: '',
      showDeleteConfirmationModal: false,
      itemId: null,
      deleteLoading: false,
    }
  },
  computed: {
    employeeGroupID() {
      return this.$route.params.id
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    employeeList() {
      return this.$store.getters['manageSalary/getEmployeesFromEmployeeGroup']
    },
  },
  async created() {
    await this.$store.dispatch('manageSalary/getEmployeesByEmployeeGroupID', {
      sub_id: this.user.sub_id,
      ID_employeeGroup: this.$route.params.id,
    })
  },
  methods: {
    async editEmployee(employeeID) {
      await this.$store.dispatch('manageSalary/getEmployeeByID', {
        sub_id: this.user.sub_id,
        ID_employee: employeeID,
      })
      this.formType = 'edit'
      this.showAddEmployeeModal = true
    },
    confirmDeletion(itemId) {
      this.itemId = itemId
      this.showDeleteConfirmationModal = true
    },
    async deleteEmployee(employeeID) {
      this.deleteLoading = true
      await this.$store.dispatch('manageSalary/deleteEmployee', {
        sub_id: this.user.sub_id,
        ID_employee: employeeID,
        employeeGroupId: this.$route.params.id,
      })
      await this.$store.dispatch('manageSalary/getEmployeesByEmployeeGroupID', {
        sub_id: this.user.sub_id,
        ID_employeeGroup: this.$route.params.id,
      })

      this.deleteLoading = false
      this.showDeleteConfirmationModal = false
    },
    async toEmployeeDetails(employeeId) {
      await this.$store.dispatch('manageSalary/getEmployeeDetails', { sub_id: this.user.sub_id, id: employeeId })
      this.$router.push(`/manage-salary/employee-group/${this.$route.params.id}/employee/${employeeId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
