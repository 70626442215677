var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'create-disbursement', params: { employeeGroupId: _vm.$route.params.id } })}}},[_vm._v(" Create Disbursement ")])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search disbursement","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.disbursementList,"search":_vm.search,"item-key":"name"},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.disbursementList.findIndex(function (disbursement) { return disbursement.id === item.id; }) + 1)+". ")]}},{key:"item.disbursementDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.disbursementDate - new Date().getTimezoneOffset())).format('DD MMM YYYY HH:mm'))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.createdAt)).format('DD MMM YYYY HH:mm'))+" ")]}},{key:"item.employeeCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employees ? item.employees.length : 0)+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCurrency(item.disbursementTotalAmount))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(("color: " + (_vm.statusColor[item.status])))},[_vm._v(" "+_vm._s(_vm.statusText[item.status])+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toDisbursementDetail(item.id)}}},[_vm._v(" View Detail ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }